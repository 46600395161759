import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./iframeSizer.contentWindow.min.js";
import Siegel1 from "./pages/Siegel1";
import Siegel2 from "./pages/Siegel2";
import Siegel3 from "./pages/Siegel3";
import Siegel4 from "./pages/Siegel4";
import Siegel5 from "./pages/Siegel5";
import Siegel6 from "./pages/Siegel6";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" exact Component={Siegel1} />
        <Route path="/siegel1" exact Component={Siegel1} />
        <Route path="/siegel2" exact Component={Siegel2} />
        <Route path="/siegel3" exact Component={Siegel3} />
        <Route path="/siegel4" exact Component={Siegel4} />
        <Route path="/siegel5" exact Component={Siegel5} />
        <Route path="/siegel6" exact Component={Siegel6} />
      </Routes>
    </Router>
  );
}

export default App;

import React, { useState, useEffect } from "react";

const Siegel4 = () => {
  const [id, setId] = useState("");
  const [mode, setMode] = useState("development");
  const [hideButton, setHideButton] = useState(false);

  useEffect(() => {
    // Set mode based on environment
    if (process.env.NODE_ENV === "production") {
      setMode("production");
    } else {
      setMode("development");
    }
    const url = window.location.href;
    if (url.includes("id=") && url.indexOf("id=") + 3 !== url.length) {
      setHideButton(true);
    }
  }, []);

  // Function to get the value of the ID from the URL
  const getIDFromURL = () => {
    const url = window.location.href;
    const idIndex = url.indexOf("id=") + 3; // Length of 'id=' is 3
    if (idIndex !== -1) {
      const id = decodeURIComponent(url.substring(idIndex));
      return id;
    }
    return "";
  };

  const updateDisplayedID = () => {
    const url = window.location.href;
    const id = getIDFromURL();
    if (url.includes("id=")) {
      setId(id);
    } else {
      setId("");
    }
  };

  useEffect(() => {
    // Initial update
    updateDisplayedID();

    // Listen for changes in the URL
    window.onpopstate = () => {
      updateDisplayedID();
    };

    // Cleanup function
    return () => {
      window.onpopstate = null;
    };
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const newId = encodeURIComponent(e.target.elements.text.value);
    const baseUrl =
      mode === "development"
        ? "https://dsev-siegel.dpms-online.de/backend/index.php?k=d937494d09437175ba4191210ee004e1&id="
        : "/backend/index.php?k=d937494d09437175ba4191210ee004e1&id=";

    const newUrl = baseUrl + newId;
    window.location.href = newUrl;
  };

  return (
    <div className="img flex justify-center items-center img-background-4">
      <form className="w-full" onSubmit={handleSubmit}>
        <input
          className="inputText uppercase poppins-semibold"
          placeholder="Bitte ausfüllen "
          id="idInput"
          type="text"
          name="text"
          value={id}
          onChange={(e) => setId(e.target.value)}
        />
        {!hideButton && (
          <button
            className="absolute top-[350px] left-[200px] border-2 border-slate-300 bg-green-600 text-md text-white px-2 py-1"
            type="submit"
          >
            Generieren
          </button>
        )}
      </form>
    </div>
  );
};

export default Siegel4;
